<template>
  <div>
    <v-row class="align-center">
      <v-col md="3">
        <CustomerSearch solo flat background-color="grey lighten-4" @input="setCustomer($event)" />
      </v-col>
      <v-col md="3">
        <company-select chips multiple v-model="searchParams.company_id" :item-value="(val) => val.id"
          :itemText="(val) => val.short_name" solo flat background-color="grey lighten-4" :dense="false"
          @change="select()" />
      </v-col>
      <v-col md="2">
        <app-date-field solo flat background-color="grey lighten-4" v-model="searchParams.due_date_start"
          label="Vencimento Inicial" @change="select()" />
      </v-col>
      <v-col md="2">
        <app-date-field solo flat background-color="grey lighten-4" v-model="searchParams.due_date_end"
          label="Vencimento Final" @change="select()" />
      </v-col>
      <v-col class="text-right" md="2">
        <v-menu v-if="$acl.can('billReceivableCreateEdit')" bottom left class="mr-1">
          <template v-slot:activator="{ on }">
            <v-btn bottom color="primary" v-on="on"> Novo </v-btn>
          </template>
          <v-list>
            <v-list-item @click="handleStoreCredit()">
              <v-list-item-icon>
                <v-icon left>mdi-currency-usd</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Novo Credito </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handleInsertBillReceivable('in')">
              <v-list-item-icon>
                <v-icon left>mdi-file-edit </v-icon>
              </v-list-item-icon>
              <v-list-item-content> Nova Conta a Receber </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <app-text-search-field :loading="isLoading" @input="handleSearchInput($event)" />
      </v-col>
      <v-col>
        <app-select v-model="searchParams.payment_term_id" :items="paymentTerms" :item-text="itemText"
          :item-value="itemValue" label="Forma de Pgto." solo flat background-color="grey lighten-4" :dense="false"
          @change="select()" clearable />
      </v-col>
      <v-col>
        <SalesmanSearch @input="setSalesman($event)" solo flat background-color="grey lighten-4" :dense="false"
          clearable />
      </v-col>
      <v-col>
        <v-chip-group v-model="searchParams.status" multiple mandatory @change="select()">
          <v-chip filter outlined value="renegotiated"> Renegociado </v-chip>
          <v-chip filter outlined value="to_pay"> A Receber </v-chip>
          <v-chip filter outlined value="paid"> Pago </v-chip>
          <v-chip filter outlined value="canceled"> Cancelado </v-chip>
        </v-chip-group>
      </v-col>
      <v-col class="text-right">
        <v-btn v-if="canMakePayment" @click="handlePrintExtract()" color="primary">
          Extrato
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-10 justify-space-between">
      <v-col>
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total a Receber
              <br />
              <b class="text-h4">{{
                $format.decimal(totals.remainder_total)
              }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined color="error" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total Vencido
              <br />
              <b class="text-h4">{{
                $format.decimal(totals.net_overdue_total)
              }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined color="success" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total Pago
              <br />
              <b class="text-h4">{{ $format.decimal(totals.paid_total) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total Selecionado
              <small class="text--secondary" v-if="selectedCount > 0">
                ({{ selectedCount }})
              </small>
              <br />
              <div class="d-flex flex-column">
                <b class="text-h5">{{ $format.decimal(selectedSum) }}</b>
                <small>PG {{ $format.decimal(selectedPaidSum) }}</small>
              </div>
            </div>
            <div class="d-flex flex-column">
              <v-btn :disabled="!canMakePayment" color="primary" @click="handlePayBillReceivable()">
                Receber
              </v-btn>
              <v-btn :disabled="!canMakePayment" class="mt-2" color="primary" outlined small
                @click="handleNegotiation()">
                Renegociar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="bill_receivables" :show-select="$acl.can('payBillReceivable')"
          v-model="selected" :server-items-length="countBillReceivable" hide-default-footer disable-sort dense
          @click:row="handleBillReceivableRowClick($event)">
          <template v-slot:[`item.company`]="{ item }">
            <company-chip :company="item.company" />
          </template>
          <template v-slot:[`item.customer`]="{ item }">
            <span v-if="item.customer">{{ item.customer.name }}</span>
            <span v-if="item.customer && item.description"> | </span>
            {{ item.description }}
          </template>
          <template v-slot:[`item.document`]="{ item }">
            <span v-if="item.origin == 'sale'">
              {{ item.sale.number }}
            </span>
            <span v-else>
              {{ item.document }}
            </span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            {{ $format.decimal(item.value) }}
            <v-icon small v-if="item.sale_id && item.sale.has_negotiation">
              mdi-information-outline
            </v-icon>
          </template>

          <template v-slot:[`item.net_paid_value`]="{ item }">
            {{ $format.decimal(item.net_paid_value) }}
          </template>
          <template v-slot:[`item.to_receive`]="{ item }">
            {{ $format.decimal(item.value - item.paid_value) }}
          </template>
          <template v-slot:[`item.issue_date`]="{ item }">
            {{ $format.dateBr(item.issue_date) }}
          </template>
          <template v-slot:[`item.due_date`]="{ item }">
            <app-due-date-label :due-date="item.due_date" :show-diff-days="true" />
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <BillReceivableStatusChip :value="item.status" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <app-icon color="info" v-if="item.has_payment && item.status == 'to_pay'">
              monetization_on
            </app-icon>
            <v-menu v-if="canEdit(item) || canCancel(item)" bottom left class="mr-1">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text icon color="primary">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-if="canEdit(item)" @click="handleEdit(item.id)">
                  <v-list-item-icon class="mr-2">
                    <app-icon>edit</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Editar</v-list-item-content>
                </v-list-item>
                <v-list-item v-if="canCancel(item)" @click="handleCancel(item.id)">
                  <v-list-item-icon class="mr-2">
                    <app-icon>cancel</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Cancelar</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <InsertBillReceivableForm ref="InsertBillReceivableForm" @store="select(searchParams.page)" />
    <UpdateBillReceivableForm ref="UpdateBillReceivableForm" @store="select(searchParams.page)" />
    <PayBillReceivableForm ref="PayBillReceivableForm" @store="handlePaymentStore()" />
    <BillReceivableDialog ref="BillReceivableDialog" />
    <BillReceivableNegotiationForm @store="reset()" ref="BillReceivableNegotiationForm" />
    <StoreCredit @store="select()" ref="StoreCredit" />
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AccountPlanSelect from "@/components/account-plan/ui/AccountPlanSelect.vue";
import BillReceivableStatusChip from "@/components/bill-receivable/sections/BillReceivableStatusChip.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";
import UpdateBillReceivableForm from "@/components/bill-receivable/forms/UpdateBillReceivableForm";

import BillReceivableDialog from "@/components/bill-receivable/sections/BillReceivableDialog";
import InsertBillReceivableForm from "@/components/bill-receivable/forms/InsertBillReceivableForm";
import PayBillReceivableForm from "@/components/bill-receivable/forms/PayBillReceivableForm";
import BillReceivableNegotiationForm from "@/components/bill-receivable/forms/BillReceivableNegotiationForm";
import StoreCredit from "@/components/bill-receivable/forms/StoreCredit";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch";

export default {
  components: {
    CompanyChip,
    CompanySelect,
    AccountPlanSelect,
    BillReceivableStatusChip,
    InsertBillReceivableForm,
    UpdateBillReceivableForm,
    BillReceivableDialog,
    PayBillReceivableForm,
    CustomerSearch,
    BillReceivableNegotiationForm,
    StoreCredit,
    SalesmanSearch
  },

  props: {
    itemText: {
      default() {
        return (val) => `${val.name}`;
      },
    },

    itemValue: {
      default() {
        return (val) => val.id;
      },
    },
  },

  data() {
    return {
      headers: [
        { text: "Empresa", value: "company", width: "5%" },
        { text: "Cliente", value: "customer" },
        { text: "Documento", value: "document", align: "right", width: "10%" },
        { text: "V. Original", value: "value", align: "right", width: "10%" },
        { text: "Pago", value: "net_paid_value", align: "right", width: "10%" },
        {
          text: "A Receber",
          value: "to_receive",
          align: "right",
          width: "10%",
        },
        {
          text: "Emissão",
          value: "issue_date",
          align: "center",
        },
        {
          text: "Vencimento",
          value: "due_date",
          align: "center",
          width: "15%",
        },
        { text: "Status", value: "status", align: "center", width: "2%" },
        {
          text: "",
          value: "action",
          align: "center",
          width: "1%",
        },
      ],

      options: {},

      searchParams: {
        company_id: [],
        salesman_id: [],
        account_plan_id: [],
        payment_term_id: null,
        text: null,
        customer_id: null,
        due_date_start: null,
        due_date_end: null,
        status: ["to_pay"],
      },

      bill_receivables: [],

      totals: {},

      selected: [],

      isLoading: false,

      paymentTerms: [],
    };
  },

  created() {
    this.setQueryParams();
  },

  async mounted() {
    await this.loadPaymentTerms();
  },

  computed: {
    selectedSum() {
      return (
        this.$calc.sum(this.selected, "value") -
        this.$calc.sum(this.selected, "paid_value")
      );
    },
    selectedPaidSum() {
      return this.$calc.sum(this.selected, "paid_value");
    },

    selectedCount() {
      return this.selected.length;
    },

    countBillReceivable() {
      return this.bill_receivables.length;
    },

    canMakePayment() {
      let nonPayaybleBills = this.selected.filter(
        (row) =>
          (row.status != "to_pay") !==
          (row.customer_id !== this.selected[0].customer_id)
      );

      return this.selectedCount > 0 && nonPayaybleBills.length == 0;
    },

    //verificar se existe algum credito nos itens selecionados
    hasCredit() {
      let nonPayaybleBills = this.selected.filter(
        (row) =>
          (row.status != "to_pay") !==
          (row.customer_id !== this.selected[0].customer_id) ||
          row.type == "out"
      );
      return this.selectedCount > 0 && nonPayaybleBills.length == 0;
    },
  },

  methods: {
    setQueryParams() {
      this.searchParams.customer_id = this.$route.query.customer_id;
      this.select();
    },


    async loadPaymentTerms() {
      await this.$http.index("settings/payment-term", { status: 1 }).then((response) => {
        this.paymentTerms = response.payment_terms;

      });
    },

    async select() {
      if (this.searchParams.customer_id) {
        delete this.searchParams.customer;
        this.isLoading = true;
        await this.$http
          .index("bill-receivable/bill-receivable", this.searchParams)
          .then((response) => {
            this.bill_receivables = response.bill_receivables;
            this.totals = response;
          });
        this.isLoading = false;
      }
    },

    handleTableUpdate(options) {
      this.options = options;
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select();
    },

    handleSearchInput(text) {
      console.log("search,,,");
      this.searchParams.text = text;
      this.select();
    },

    handleInsertBillReceivable(type) {
      this.$refs.InsertBillReceivableForm.open(type);
    },

    handlePayBillReceivable() {
      this.$refs.PayBillReceivableForm.open(this.selected);
    },

    handlePaymentStore() {
      this.selected = [];
      this.select(this.searchParams.page);
    },

    handleBillReceivableRowClick(billReceivable) {
      this.$refs.BillReceivableDialog.open(billReceivable.id);
    },

    canEdit(billReceivable) {
      return false;

      return (
        this.$acl.can("billReceivableCreateEdit") &&
        !this.hasPayments(billReceivable) &&
        billReceivable.type == "in" &&
        billReceivable.status == "to_pay" &&
        billReceivable.origin == "custom"
      );
    },
    canCancel(billReceivable) {
      return (
        this.$acl.can("billReceivableCancel") &&
        !this.hasPayments(billReceivable) &&
        billReceivable.type == "in" &&
        billReceivable.status == "to_pay" &&
        billReceivable.origin == "custom"
      );
    },

    hasPayments(billReceivable) {
      return billReceivable.paid_value != 0;
    },

    setCustomer(customer) {
      this.selected = [];
      this.searchParams.customer_id = customer.id;
      this.searchParams.customer = {
        id: customer.id,
        name: customer.name,
      };

      this.select(1);
    },

    setSalesman(salesman) {
      this.searchParams.salesman_id = salesman.id;
      this.select();
    },

    handleNegotiation() {
      this.$refs.BillReceivableNegotiationForm.open(this.selected);
    },
    handleStoreCredit(bill_receivable_id) {
      this.$refs.StoreCredit.open(bill_receivable_id);
    },
    handlePrintExtract() {
      let bill_receivables_ids = this.selected.map((item) => item.id);

      this.$print.customPreview(
        `/contas-a-receber/cliente/${this.searchParams.customer_id}/extrato-de-debitos/${bill_receivables_ids}`
      );
    },

    handleCancel(id) {
      this.$http.destroy("bill-receivable/bill-receivable", id);
      this.select();
    },

    handleEdit(id) {
      this.$refs.UpdateBillReceivableForm.open(id);
    },
    reset() {
      this.selected = [];
      this.select();
    },
  },
};
</script>

<style></style>
